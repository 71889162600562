<template>
  <div class="inlibrary">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
export default {
  components: Component.components,
  name: 'OutLibrary',
  data () {
    return {
      id: '',
      tableField: tableField,
      tableData: tableData,
      paginationData: {
        total: 0
      },
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },

  created () {
    this.myBidData()
  },
  methods: {
    buttonClick: function (data) {
      if (data.openView === 'openView') {
        this.$router.push({ params: { viewName: data.alias }, query: data.query })
        this.$emit('OpenView', { openView: data.alias })
      }
    },
    // 获取采购库存-物料出库数据列表
    myBidData () {
      var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      let ckksTime = ''
      let ckjsTime = ''
      if (this.childData.searchData.ckTime) {
        ckksTime = dayjs(this.childData.searchData.ckTime[0]).format('YYYY-MM-DD HH:mm:ss')
        ckjsTime = dayjs(this.childData.searchData.ckTime[1]).format('YYYY-MM-DD 23:59:59')
      }
      const obj = {
        rule_id: this.childData.searchData.rule_id,
        ruleNameId: this.childData.searchData.update_userid,
        tag: this.childData.searchData.tag,
        start_time: ckksTime,
        end_time: ckjsTime,
        proof_type: this.childData.searchData.proof_type
      }
      request('/api/order/PurchasingSystem/queryEkpoys?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + pageSize, 'GET', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      if (clickBtn.operationButton === 'see') {
        this.$router.push({ params: { viewName: 'AddOutLibrary' }, query: { id: data.id } })
      } else {
        this.id = data.id
        this.deliverGoods(data)
      }
    },
    // 撤销
    revoke () {
      const id = this.id
      request('/api/order/PurchasingSystem/updateOutPurchasinglier?' + 'id=' + id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '操作成功!'
          })
          this.myBidData()
        }
      })
    },
    // 撤销弹框
    deliverGoods (data) {
      this.$confirm('确认要撤销吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.revoke()
      })
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  }
}
</script>

<style scoped lang="scss">
  .inlibrary .tableList{
    padding: 0 30px !important;
  }
</style>
